<template>
  <div>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-container fluid>
      <v-row class="header-title-actions">
        <v-col cols="12" sm="6" md="6">
          <h1 class="page-title">{{ $tc("users.title", 2) }}</h1>
        </v-col>

        <v-col class="align-end" cols="12" sm="6" md="6">
          <v-btn color="buttons" @click="showAddDialog" :loading="loading">
            <v-icon left> fas fa-plus </v-icon>
            {{ $tc("users.add") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <!-- <v-col cols="12" sm="12" md="12">
          <search :loading="loading" :name="$tc('users.title', 1)" />
        </v-col> -->

        <v-col cols="12" sm="12" md="12">
          <files-table
            @menuClick="handleMenuActions($event)"
            :items="users"
            @activeChange="switchActiveChanged"
            :loading="loading"
            :headers="headers"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-if="addDialog" persistent v-model="addDialog" max-width="600px">
      <add-item
        :selectedItem="selectedItem"
        :admin="true"
        @success="userCreated"
        @cancel="addDialog = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import filesTable from "@/components/files-table";
import addItem from "../../account-information/add-user";

export default {
  name: "Users",
  components: {
    addItem,
    filesTable,
  },
  data() {
    return {
      users: [],
      groups: [],
      snackbarText: "",
      snackbar: false,
      loading: true,
      addDialog: false,
      selectedItem: null,
      headers: [
        {
          text: this.$tc("headers.name"),
          value: "name",
          width: "150px",
        },
        {
          text: this.$tc("headers.email"),
          value: "email",
          width: "150px",
        },

        {
          text: this.$tc("headers.role"),
          value: "role",
        },
        {
          text: this.$tc("headers.group"),
          value: "groupName",
        },
        {
          text: this.$tc("headers.active"),
          value: "active",
        },
        {
          text: this.$tc("headers.createdAt"),
          value: "createdAt",
          width: "200px",
        },
      ],
    };
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    ...mapActions(["cleanSearch"]),

    showAddDialog() {
      this.selectedItem = null;
      this.addDialog = true;
    },

    switchActiveChanged(data) {
      this.loading = true;
      db.collection("users")
        .doc(data.id)
        .update({
          active: data.active,
          modifiedAt: new Date(),
          modifiedBy: this.user.uid,
        })
        .then((response) => {
          this.loading = false;
          this.snackbarText = data.active
            ? this.$tc("users.now_active")
            : this.$tc("users.now_inactive");
          this.snackbar = true;
        })
        .catch((err) => {
          this.loading = false;
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
        });
    },

    userCreated(data) {
      this.snackbarText = this.selectedItem
        ? this.$tc("users.updated")
        : this.$tc("users.created");
      this.snackbar = true;
      this.addDialog = false;
      this.selectedItem = null;
      this.getUsers();
    },

    async getUsers() {
      await this.$binding("groups", db.collection("groups"));

      this.loading = true;
      this.users = [];

      await db
        .collection(`users`)
        .get()
        .then((response) => {
          response.forEach((item) => {
            let user = item.data();
            user.id = item.id;
            let group = this.groups.find(
              (item) => item.users && item.users.includes(user.id)
            );

            user.groupName = group ? group.name : "";
            this.users.push(user);
          });
        });
      this.loading = false;
    },
  },
  mounted() {
    fb.auth()
      .currentUser.getIdTokenResult()
      .then((result) => {
        let systemAdministrator = result.claims.systemAdministrator
          ? true
          : false;
        if (systemAdministrator) {
          this.cleanSearch();
          this.getUsers();
        } else {
          return this.$router.push("/");
        }
      });
  },
};
</script>

<style>
.v-dialog--fullscreen {
  border-radius: 0 !important;
  background-color: #000000e3;
}
</style>